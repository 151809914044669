/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '../node_modules/ngx-red-zoom/styles/_base';
@import './node_modules/ngx-red-zoom/styles/_style-window';
@import 'node_modules/ngx-red-zoom/styles/_style-overlay';
@import 'node_modules/ngx-red-zoom/styles/_style-magnifier';

body {
   background-color: rgba(200,212,204,1);
   color: black;
   //font-size:200%;
}

.card {
    background-color: rgba(220,232,224,1);
}

.oddfront {
    background-image: linear-gradient(to right, rgba(220,232,224,1), rgba(200,212,204,1));
    //background-image: linear-gradient(to right, red, blue);
    margin-bottom:15px;
}

.evenfront {
    background-image: linear-gradient(to right, rgba(200,212,204,1), rgba(220,232,224,1));
    //background-image: linear-gradient(to right, green, yellow);
    margin-bottom:15px;
}

.top-card {
    background-image: linear-gradient(to right, rgba(220,232,224,1), rgba(200,212,204,1));
    border-color: white;
    border-radius: 15px;
    border-width: 4px;
    margin-bottom: 15px;
}


.capped {
   p:first-letter {
      color: #cd7f32;
      float: left;
      font-family: Georgia;
      font-size: 200%;
      line-height: 100%;
      padding-top: 4px;
      padding-right: 4px;
      margin-right: 3px;
      padding-left: 3px;
      margin-top: 6px;
      border-color: #cd7f32;
      border-width: 2px;
      border-style: solid;
   }
}

.gu-mirror { 
      position: fixed !important;
      margin: 0 !important;
      z-index: 9999 !important;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      filter: alpha(opacity=80);
      pointer-events: none;
}

.gu-hide {
      left: -9999px !important;
}

.gu-unselectable {
      -webkit-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
}

.gu-transit {
      opacity: 0.2;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
      filter: alpha(opacity=20);
}


.fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}


.files-list {
  margin-top: 1.5rem;

  .single-file {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
    border: dashed 1px #979797;
    margin-bottom: 1rem;
  

    .delete {
      display: flex;
      margin-left: 0.5rem;
      cursor: pointer;
      align-self: flex-end;
    }
    


    display: flex;
    flex-grow: 1;

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #353f4a;
      margin: 0;
    }

    .size {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
    }    

    .info {
      width: 100%;
      margin-left: 15px;
    }
  }
}

@keyframes shake {
    0%{
        transform: translate(1px, 1px) rotate(0deg)
    }

    10%{
        transform: translate(-1px, -2px) rotate(-1deg)
    }

    20%{
        transform: translate(-3px, 0px) rotate(1deg)
    }

    30%{
        transform: translate(3px, 2px) rotate(0deg)
}

    40%{
        transform: translate(1px, -1px) rotate(1deg)
    }

    50%{
        transform: translate(-1px, 2px) rotate(-1deg)
    }

    60%{
        transform: translate(-3px, 1px) rotate(0deg)
    }

    70%{
        transform: translate(3px, 1px) rotate(1deg)
    }

    80%{
        transform: translate(-1px, -1px) rotate(1deg)
    }

    90%{
        transform: translate(1px, 2px) rotate(0deg)
    }

    100%{
        transform: translate(1px, -2px) rotate(-1deg)
    }
}

.app-session-modal-window {
    z-index: 1999 !important;
    height: 100vh;
}

.app-session-modal-backdrop {
    z-index: 1998 !important;
}

.modal {
    z-index:1060 !important;
}

.black-modal-cross {
    position: relative;
    top: 0px;
    right: 15px;
    color: red !important;
    font-size: 2rem !important;
    font-weight: 900 !important;
    z-index: 2000;
}

.red-zoom--style--carpeta--ventas {
    @include red-zoom-style-magnifier(
        $lens-size:             250px,
        $overlay-color:         rgba(#0b3b59, .5),
    );
    .red-zoom__lens-image {
        --red-zoom-min-scale-factor: 1;
        --red-zoom-max-scale-factor: 5;

        min-width: calc(var(--red-zoom-thumb-w) * var(--red-zoom-min-scale-factor));
        min-height: calc(var(--red-zoom-thumb-h) * var(--red-zoom-min-scale-factor));
        max-width: calc(var(--red-zoom-thumb-w) * var(--red-zoom-max-scale-factor));
        max-height: calc(var(--red-zoom-thumb-h) * var(--red-zoom-max-scale-factor));

    }
}

